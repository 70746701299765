import React from 'react'
import { ThemeProvider } from '@context/themeContext'
import '@scss/reset.global.scss'
import '@scss/styles.global.scss'
import typography from '@helpers/typography'
import vhCheck from 'vh-check'
import useWhatInput from 'react-use-what-input'

typography.injectStyles()

export const wrapRootElement = ({ element }) => {
  vhCheck({
    cssVarName: 'vh-offset',
    redefineVh: true,
    bind: true
  })

  return <ThemeProvider>{element}</ThemeProvider>
}
